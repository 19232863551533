<template>
    <div class="vue-box">

        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>采购管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <header-project :project="project"></header-project>

        <div class="c-panel">
            <!-- 参数栏 -->
            <el-form :inline="true" size="mini">
                <el-form-item label="标题：">
                    <el-input v-model="p.title" placeholder="模糊查询"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <input-enum enumName="purchaseStateEnum" v-model="p.state"></input-enum>
                </el-form-item>
                <el-form-item style="min-width: 0px">
                    <el-button type="primary" icon="el-icon-search" @click="f5();">查询 </el-button>
                    <el-button type="primary" icon="el-icon-plus" @click="add">增加</el-button>
                </el-form-item>
            </el-form>
            <!-- <div class="c-title">数据列表</div> -->
            <el-table :data="dataList" size="mini">
                <el-table-column label="序号" prop="id"></el-table-column>

                <el-table-column label="标题" prop="title"></el-table-column>
            
                <el-table-column label="数量" prop="num"></el-table-column>
                <el-table-column label="预计完成时间" prop="planDate"></el-table-column>
                <el-table-column label="场景" prop="typeEnum"></el-table-column>
                <el-table-column label="采购员" prop="receiver"></el-table-column>
                <el-table-column label="收货电话" prop="mobile"></el-table-column>
                <el-table-column label="状态" prop="stateEnum"></el-table-column>
                <el-table-column prop="address" label="操作" width="220px">
                    <template slot-scope="s">
                         <el-button class="c-btn" type="success" icon="el-icon-star-off" @click="line(s.row)">明细
                        </el-button>
                        <el-button class="c-btn" type="primary" icon="el-icon-edit" @click="update(s.row)">修改
                        </el-button>
                        <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page"
                    :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]"
                    @current-change="f5(true)" @size-change="f5(true)">
                </el-pagination>
            </div>
        </div>

        <!-- 增改组件 -->
        <add-or-update ref="add-or-update"></add-or-update>
    </div>
</template>

<script>
import HeaderProject from '../../sa-resources/com-view/header-project.vue';
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
import addOrUpdate from './add.vue';
export default {
    components: {
        addOrUpdate,
        inputEnum,
        HeaderProject
    },
    data() {

        return {
            p: { pageSize: 10, page: 1, title: '', code: '', state: '' },
            dataCount: 0,
            dataList: [],
            project: {},
            title: "采购单信息"
        }
    },
    methods: {
        // 数据刷新
        f5: function () {
            this.sa.put("/purchase/listPage", this.p).then(res => {
                this.dataList = res.data.content.map((item) => {
                    return item;
                });
                this.dataCount = res.data.totalElements;
            });
        },
        // 删除
        del: function (data) {
            this.sa.confirm('是否删除，此操作不可撤销', function () {
                this.sa.delete("/purchase/delete/" + data.id).then(res => {
                    console.log(res)
                    this.sa.arrayDelete(this.dataList, data);
                    this.sa.ok(res.message);
                });

            }.bind(this));
        },
        //更新
        update(row) {
            this.$refs['add-or-update'].open(row);
        },
        //添加
        add: function () {
            this.$refs['add-or-update'].open({ projectId: this.project.id });
        },
        line(row) {
            // console.log(5555555555,row);
            this.sa_admin.navigateTo('purchaseLine-list', row);
        },
        goto(project) {
            this.sa_admin.navigateTo("project-detail", project);
        }
    },
    created: function () {
        this.project = this.sa_admin.params;
        this.p.projectId = this.project.id;
        this.f5();
    }
}

</script>

<style>
.header {
    margin: 20px;
    margin-top: 10px;
    
}
</style>